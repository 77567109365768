<template>
    <section class="zc-ver-reserva mt-4 ml-4" v-loading="loading">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                    <el-breadcrumb-item v-if="$usuario.tipo == 4 || $usuario.tipo == 6" :to="{ name: 'zonas.historial' }">Zonas comunes</el-breadcrumb-item>
                    <el-breadcrumb-item v-else :to="{ name: 'zonas.reservas.listado' }">Zonas comunes</el-breadcrumb-item>
                    <el-breadcrumb-item>Reserva</el-breadcrumb-item>
                </el-breadcrumb>
                <span v-show="[21,24].includes(reserva.estado)" class="br-4 mx-2 p-2 f-16 f-400 bg-rojo-claro rounded-pill">Cancelada</span>
                <span v-show="[22,23].includes(reserva.estado)" class="br-4 mx-2 p-2 f-16 f-400 bg-rojo-claro rounded-pill">Rechazada</span>
                <span v-show="[31,32,33].includes(reserva.estado) && currentTime < reserva.fecha" class="br-4 mx-2 p-2 f-16 f-400 bg-verde-claro rounded-pill">Reservada</span>
                <span v-show="[31,32,33].includes(reserva.estado) && currentTime > reserva.fecha" class="br-4 mx-2 p-2 f-16 f-400 bg-finalizado text-white rounded-pill">Finalizada</span>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-lg-4">
                <div class="row">
                    <p class="col-12 f-20 f-500 mb-3">Reserva de {{reserva.zona.nombre}}</p>
                    <div class="col-12 mb-3">
                        <div class="d-flex tres-puntos">
                            <div class="wh-32px rounded-circle d-middle-center bg-azulclaro my-auto">
                                <i class="icon-account-outline f-20 op-05" />
                            </div>
                            <div class="tres-puntos ml-2">
                                <p class="f-15 f-500 tres-puntos"> {{reserva.usuario.nombre}} </p>
                                <p class="f-15 tres-puntos text-86">{{reserva.usuario.vivienda}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <div class="d-flex tres-puntos">
                            <i class="icon-calendar f-20 op-05" />
                            <div class="tres-puntos ml-2">
                                <p class="f-15 f-500 tres-puntos"> {{reserva.fecha | helper-fecha('DD MMMM YYYY')}} </p>
                                <p class="f-15 tres-puntos text-86">{{formatearHora(reserva.hora_inicio)}} - {{formatearHora(reserva.hora_fin)}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- section justificación  -->
                    <div v-if="reserva.zona.uso_necesario" class="col-12 f-15">
                        <p class="f-500">{{ reserva.zona.uso_dato_pedidos}}</p>
                        <p>{{ reserva.uso_justificacion }}</p>
                    </div>
                     <!-- section Asistentes  -->
                    <div class="col-12 mb-3 my-3">
                        <div class="d-flex tres-puntos">
                        <p class="f-15 f-500 tres-puntos"> Asistentes: </p>
                        <p class="f-15 tres-puntos ml-1">  {{ reserva.cantidad_personas}} </p>
                            <div v-if="reserva.zona.bloques_datos != 0" class="tres-puntos ml-2">
                                <p class="f-15 f-500 cr-pointer text-info" @click="$refs.modalAsistentes.toggle()"> Ver listado </p>
                            </div>
                        </div>
                    </div>
                    <!-- section de estado de pago  -->
                    <div v-if="reserva.zona.pago_tipo">
                        <p class="col-12 f-20 f-500 mb-2 mt-3">Pago</p>
                        <div class="col-12 d-flex a-center mb-3">
                            <div class="col-auto d-flex">
                                <img src="/img/generales/pago_efectivo.svg" alt="" class="mr-2" width="30">
                                <div class="tres-puntos ml-2">
                                    <p class="f-17 f-500 tres-puntos">{{ reserva.zona.pago_valor }} </p>
                                    <p class="f-15 tres-puntos text-86">Efectivo</p>
                                </div>
                            </div>
                            <!-- div cuando no se ha pagado la reserva  -->
                            <div v-show="!reserva.pago_adjunto" class="col mb-3">
                                <div v-if="[12,13,14].includes(reserva.estado)" class="d-flex align-items-center">
                                    <i class="icon-alerta_2 mr-2" />
                                    <div class="ml-2">
                                        <p class="f-15 text-86">Plazo máximo de pago el {{ formatearFecha(reserva.pago_max_fecha,'DD MMM YYYY h:mm a') }}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- div cuando ya se pagó la reserva  -->
                            <div v-show="reserva.pago_adjunto" class="col mb-3">
                                <div class="col">
                                    <div class="d-flex align-items-center">
                                        <i class="icon-confirmado text-general mr-2" />
                                        <div class="ml-2">
                                            <p class="f-15 text-86">Pagado el {{ formatearFecha(reserva.pago_fecha,'DD MMM YYYY h:mm a') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="reserva.pago_adjunto" class="row justify-content-center text-general f-400 cr-pointer" @click="$refs.verInfoPago.toggle()">
                                    <p>Ver detalle</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- section de botones  -->
                    <div class="col-12 d-middle">
                        <div v-if="[12,13,14].includes(reserva.estado) && reserva.pago_fecha != null" class="col-6 mt-3">
                            <button class="bg-verde-claro h-32px f-14 w-100 border-0 br-4" @click="aprobarReserva">
                                <p class="d-flex op-05 d-middle-center">
                                    <i class="icon-money" />
                                    Aprobar pago
                                </p>
                            </button>
                        </div>
                        <div v-if="[11].includes(reserva.estado)" class="col-6 mt-3">
                            <button class="bg-verde-claro h-32px f-14 w-100 border-0 br-4" @click="aprobarReserva">
                                <p class="d-flex op-05 d-middle-center">
                                    <i class="icon-confirmado" />
                                    Aprobar
                                </p>
                            </button>
                        </div>
                         <div v-if="[11].includes(reserva.estado)  && reserva.pago_fecha == null" class="col-6 mt-3">
                            <button class="bg-rojo-claro h-32px f-14 w-100 border-0 br-4" @click="rechazarReserva">
                                <p class="d-flex op-05 d-middle-center">
                                    <i class="icon-denied" />
                                    Rechazar
                                </p>
                            </button>
                        </div>
                        <div v-if="[12,13,14].includes(reserva.estado)  && reserva.pago_fecha != null" class="col-6 mt-3">
                            <button class="bg-rojo-claro h-32px f-14 w-100 border-0 br-4" @click="rechazarReserva">
                                <p class="d-flex op-05 d-middle-center">
                                    <i class="icon-denied" />
                                    Rechazar
                                </p>
                            </button>
                        </div>
                    </div>
                    <!-- section de cancelados -->
                    <div v-if="[22,23].includes(reserva.estado)" class="br-4 mx-3 col-sm-5 col-md-6 col-lg-8 mt-3 bg-rojo-claro py-1">
                        <p v-show="reserva.estado==22" class="f-13 f-500">Rechazada por el administrador</p>
                        <p v-show="reserva.estado==23" class="f-13 f-500">Rechazada atomáticamente</p>
                        <p class="f-14">{{ reserva.estado_comentario }}</p>
                        <p class="f-12">{{ formatearFecha(reserva.updated_at,'DD MMM YYYY h:mm a') }}</p>
                    </div>
                    <div v-if="[21,24].includes(reserva.estado)" class="br-4 mx-3 col-sm-5 col-md-6 col-lg-8 mt-3 bg-rojo-claro py-1">
                        <p v-show="reserva.estado==21" class="f-13 f-500">Cancelada por el residente</p>
                        <p v-show="reserva.estado==24" class="f-13 f-500">Cancelada por falta de pago</p>
                        <p class="f-14">{{ reserva.estado_comentario }}</p>
                        <p class="f-12">{{ formatearFecha(reserva.updated_at,'DD MMM YYYY h:mm a') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="row">
                    <p class="col-12 f-20 f-500 mb-3">Datos de la zona</p>
                    <div class="row mx-0">
                        <div v-for="imagen in reserva.zona.imagenes" :key="imagen.id" class="col-auto mb-2" @click="verGaleriaImagenes">
                            <div class="container-img">
                                <img :src="imagen.imagen" alt="" class="w-100 h-100 obj-cover br-4 cr-pointer">
                            </div>
                        </div>
                        <p class="col-12 text-right" @click="verGaleriaImagenes">Ver más</p>
                    </div>
                    <p class="col-12 f-17 f-500">
                        {{reserva.zona.pago_valor }} x 
                        <span class="f-300 mx-1">Bloque de {{ diferenciaMinutos(reserva.hora_inicio,reserva.hora_fin) }} minutos</span>
                    </p>
                    <p class="col-12 f-15 mx-0 word-break">{{reserva.zona.descripcion}}.</p>
                    <div v-if="reserva.zona.reglas" class="col-10 f-15">
                        <p class="f-15 f-500 mt-3 my-1">Reglas de uso</p>
                        <el-input placeholder="reglas de la zona" v-model="reserva.zona.reglas" :disabled="true" type="textarea" :autosize="{ minRows: 8, maxRows: 8}"/>
                        <div class="row justify-content-end text-general f-400 mx-0 cr-pointer" @click="verMasReglas">
                            <p>Ver mas</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="row">
                    <p class="col-12 f-20 f-500 mb-3">Reservas del mismo día</p>
                    <div class="col-12">
                        <div class="contenedor-fechas-reservas">
                          <div v-for="(horario,i) in horarios" :key="i">
                            <div class="hora d-middle-center f-14 border-bottom" :style="`margin-bottom:1px;background-color:${horario.color}`">
                                <p v-show="horario.case==0" class="like-tooltip f-12 text-86">Disponible</p>
                                <p v-show="horario.case==1" class="like-tooltip f-12 text-86">Reservado</p>
                                <p v-show="horario.case==2" class="like-tooltip f-12 text-86">Pendiente de aprobación</p>
                                <p v-show="[3,4,5,6,7].includes(horario.case) && bloqueActual != horario.hora_apertura" class="like-tooltip f-12 text-86">Disponible reservado</p>
                                <p class="w-100 text-center py-2"> {{horario.hora_apertura}} - {{horario.hora_cierre}} </p>
                                <i v-if="horario.compartido" class="icon-group-outline"></i>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-confirmar ref="modalabrirAprobarSolicitudReserva" titulo="Aprobar solicitud de reserva" mensaje="¿Desea aprobar la solicitud?" tamano="modal-sm" adicional="Aprobar" @adicional="aprobarReservacion" />
        <modal-rechazar-reserva ref="modalAbrirRechazarReserva" @rechazar="rechazarReservacion"/>
        <modal-ver-img ref="modalAbrirVerImagenes" />
        <modal ref="verInfoPago" titulo="Detalle del pago">
            <p class="text-center mb-2">{{ reserva.pago_comentario || 'Sin comentarios'}}</p>
            <!-- <p style="font-style:italic;text-align:end;">{{ formatearFecha(reserva.pago_fecha,'DD MMM YYYY h:mm a') }}</p> -->
            <div class="d-flex justify-content-center p-2">
                <img :src="reserva.pago_adjunto || '/img/no-imagen/noimagenanuncio.svg'" style="max-height:800px;max-width:500px;">
            </div>
        </modal>
        <modal ref="modalAsistentes" titulo="Lista de asistentes">
            <div class="row mx-0 justify-content-center">
                <div v-for="(a, key) in reserva.asistentes" :key="key" class="col-10 text-black d-middle">
                    <i class="icon-confirmado mr-2"></i>
                    <p class="f-18"> {{ a.datos }} </p>
                </div>
            </div>
        </modal>
        <modal ref="modalVerMasReglas" titulo="Recomendaciones de uso" tamano="modal-lg">
            <div class="row">
                <div class="col-12">
                    <p class="f-12 pl-2 label-inputs">Recomendaciones de uso</p>
                    <el-input v-model="reserva.zona.reglas" placeholder="Recomendaciones" type="textarea" autosize />
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import moment from 'moment'
import Reservas from '~/services/reservas'
import Zonas from '~/services/zonas'
export default {
    components:{
        modalRechazarReserva: () => import('../partials/modalRechazarReserva'),
        modalVerImg: () => import('../partials/modalVerImg'),
    },
    data(){
        return{
            loading: false,
            reserva:{
              id:null,
              zona:{
                id: null,
                nombre: '',
                imagenes: []
              },
              pago_verificacion: null,
              usuario:{
                id: null,
                nombre: '',
              }
            },
            horariosColor: [
                "#FFFFFF",
                "#DADADA",
                "#FFD54F",
                "#D5F5E3",
                "#ABEBC6",
                "#82E0AA",
                "#58D68D",
                "#2ECC71"
            ],
            horarios: []

        }
    },
    created(){
      this.obtenerReserva()
    },
    computed: {
        // ultimoEstado(){
        //     return this.reserva.estados[0]
        // },
        currentTime(){
            return moment().format('YYYY-MM-DD hh:mm:ss').toString()
        },
        bloqueActual(){
            return moment(this.reserva.fecha).format('hh:mm')
        }
    },
    methods:{
        aprobarReserva(){
            this.$refs.modalabrirAprobarSolicitudReserva.toggle()
        },
        rechazarReserva(){
            this.$refs.modalAbrirRechazarReserva.toggle()
        },
        verGaleriaImagenes(){
            this.$refs.modalAbrirVerImagenes.setImages(this.reserva.zona.imagenes)
            this.$refs.modalAbrirVerImagenes.toggle()
        },

        async obtenerReserva(){
          try {
            this.loading = true
            const {data} = await Reservas.obtenerReserva(this.$route.params.id_reserva)
            this.loading = false
            if(data.success){
              this.reserva = data.data
              this.obtenerHorarios()
            }
          } catch (e){
            this.loading = false
            this.errorCatch(e)
          }
        },

        async obtenerHorarios(){
          try {
            this.loading = true
            const {data} = await Zonas.horarios(this.reserva.zona.id,this.reserva.fecha)
            console.log(data)
            this.loading = false
            if(data.success){
              this.horarios = data.data
              this.horarios.map(el=>{
                  el.case = el.color
                  el.color = this.horariosColor[el.color]
                  return el
              })
            }
          } catch (e){
            this.loading = false
            this.errorCatch(e)
          }
        },

        async aprobarReservacion(){
          try {
            this.loading = true

            let payload = {
              id_reserva : this.reserva.id,
              rechazado : 0,
            }
            
            const {data} = await Reservas.cambiarEstado(payload)
            if(data.success){
              this.obtenerReserva()
              this.$notify({
                title: 'Aprobar Reserva',
                message: data.mensaje,
                type: 'success'
              });
            }else{
              this.$notify({
                title: 'Aprobar Reserva',
                message: data.mensaje,
                type: 'warning'
              });
            }
          } catch (e){
            this.loading = false
            this.errorCatch(e)
          }
        },

        async rechazarReservacion(comentario){
          try{
            this.loading = true
            let payload = {
              id_reserva : this.reserva.id,
              rechazado : 1,
              comentario : comentario,
            }

            const {data} = await Reservas.cambiarEstado(payload)
            if(data.success){
              this.obtenerReserva()
              this.$refs.modalAbrirRechazarReserva.toggle()
              this.$refs.modalAbrirRechazarReserva.reset()
              this.$notify({
                title: 'Rechazar reserva',
                message: data.mensaje,
                type: 'success'
              });
            }else{
              this.$notify({
                title: 'Rechazar reserva',
                message: data.mensaje,
                type: 'warning'
              });
            }
          } catch (e){
            this.loading = false
            this.errorCatch(e)
          }
        },

        diferenciaMinutos(inicio,fin){
            let fechaInicio = moment(inicio,'HH:mm:ss')
            let fechaFin = moment(fin,'HH:mm:ss')
            let duracion = moment.duration(fechaFin.diff(fechaInicio))
            return duracion.asMinutes()
        },
        verMasReglas(){
            this.$refs.modalVerMasReglas.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>
.zc-ver-reserva{
    i.icon-alerta_2{
        color:#FF8B32;
    }
    .container-img{
        width: 112px;
        height: 74px;
    }
    .contenedor-fechas-reservas{
        width: 300px;
        max-width: 300px;
        height: 352px;
        border: 1px solid #dbdbdb;
        border-radius: 8px;
        .hora{
            height: 44px;
            border-bottom: 1px solid #dbdbdb;
            position: relative;
            &:first-child{
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
            .like-tooltip{
                background: #fff;
                border-radius: 20px;
                position: absolute;
                bottom: -11px;
                box-shadow: 0px 3px 6px #5d5d5d14;
                border: 1px solid #F5F5F5;
                right: 0px;
                transition: .35s;
                opacity: 0;
                text-align: center;
                padding: 1px 10px 1px 10px;
                z-index: 1;

            }
            &:hover{
                .like-tooltip{
                    transition: .45s;
                    opacity: 1;
                }
            }
            &:last-child{
                border-bottom: none;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
            &.estado{
                &-pendiente{
                    background: #FFD54F;
                }
                &-actual{
                    background: #93EDCF;
                }
                &-reservado{
                    background: #f5f5f5;
                    position: relative;
                    i{
                        position: absolute;
                        right: 9px;
                        top: 5px;
                        font-size: 21px;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}
</style>